import { FC, ReactElement } from 'react'
import Script from 'next/script'

import { YM_ID } from 'constants/data'

const YAHead = (): ReactElement => (
  <Script strategy="afterInteractive">
    {`
(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(${YM_ID}, "init", {
    defer: true,
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
});
`}
  </Script>
)

const YABody: FC = () => (
  <noscript>
    <div>
      <img
        decoding="async"
        loading="lazy"
        src={`https://mc.yandex.ru/watch/${YM_ID}`}
        style={{ position: 'absolute', left: '-9999px', width: '0', height: '0' }}
        alt="yandex track"
      />
    </div>
  </noscript>
)

export { YABody, YAHead }
