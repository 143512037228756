import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { AuthStatus } from 'constants/auth/auth-data'
import { STUDENT_TAG } from 'components/students/students-data'
import {
  getUserProfile,
  getAuthorization,
  getAccessToken,
  getCookieToken,
  getExpirationTime,
  getCurrentTime,
} from './selectors'
import { InputNames, STATUSES } from 'constants/user-documents-data'

const checkUserProfileFilled = (profile: any) =>
  profile.firstName &&
  profile.lastName &&
  Boolean(profile.Specialities?.length) &&
  profile.workplace

const useAuth = () => {
  const userProfile = useSelector(getUserProfile)
  const accessToken = useSelector(getAccessToken)
  const isCookieToken = useSelector(getCookieToken)
  const authorizationStatus = useSelector(getAuthorization)
  const expireTime = useSelector(getExpirationTime)
  const currentTime = useSelector(getCurrentTime)

  const isAuthorized = authorizationStatus === AuthStatus.AUTHORIZED
  const isNotAuthorized = !isAuthorized
  const isAdmin = isAuthorized && userProfile?.isAdmin
  const isSponsor = isAuthorized && userProfile?.tags?.includes('sponsor')
  const isFilledUser = isAuthorized && checkUserProfileFilled(userProfile)
  const isStudent = isAuthorized && userProfile?.tags?.includes(STUDENT_TAG)
  const isDiplomaApproved = userProfile?.documents?.[InputNames.DIPLOMA] === STATUSES.APPROVED
  const isPassportApproved = userProfile?.documents?.[InputNames.PASSPORT] === STATUSES.APPROVED
  const isCertificateApproved =
    userProfile?.documents?.[InputNames.CERTIFICATE] === STATUSES.APPROVED

  const anketaProfile = useMemo(
    () => ({
      fullName: (
        userProfile.firstName +
        ' ' +
        userProfile.lastName +
        ' ' +
        userProfile.secondName
      ).trim(),
      Specialities: userProfile.Specialities,
      workplace: userProfile.workplace,
    }),
    [
      userProfile.firstName,
      userProfile.lastName,
      userProfile.secondName,
      userProfile.Specialities,
      userProfile.workplace,
    ]
  )

  const isLoaded = 'email' in userProfile

  return {
    authorizationStatus,
    userProfile,
    isAuthorized,
    isNotAuthorized,
    isAdmin,
    isSponsor,
    isStudent,
    isFilledUser,
    isCookieToken,
    accessToken,
    expireTime,
    currentTime,
    anketaProfile,
    isLoaded,
    checkUserProfileFilled,
    isDiplomaApproved,
    isPassportApproved,
    isCertificateApproved,
  }
}

export { useAuth, checkUserProfileFilled }
