import { ReactElement } from 'react'

import { AMPLITUDE_KEY } from 'constants/data'

const AMHead = (): ReactElement => (
  <script
    defer
    dangerouslySetInnerHTML={{
      __html: `
      !function(e,t){var n=e.amplitude||{_q:[],_iq:{}},i=t.createElement("script");i.type="text/javascript",i.integrity="sha384-girahbTbYZ9tT03PWWj0mEVgyxtZoyDF9KVZdL+R53PP5wCY0PiVUKq0jeRlMx9M",i.crossOrigin="anonymous",i.async=!0,i.src="https://cdn.amplitude.com/libs/amplitude-7.2.1-min.gz.js",i.onload=function(){e.amplitude.runQueuedFunctions||console.log("[Amplitude] Error: could not load SDK")};var r=t.getElementsByTagName("script")[0];function o(e,t){e.prototype[t]=function(){return this._q.push([t].concat(Array.prototype.slice.call(arguments,0))),this}}r.parentNode.insertBefore(i,r);for(var s=function(){return this._q=[],this},l=["add","append","clearAll","prepend","set","setOnce","unset"],a=0;a<(null==l?void 0:l.length);a++)o(s,l[a]);n.Identify=s;for(var u=function(){return this._q=[],this},c=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"],d=0;d<(null==c?void 0:c.length);d++)o(u,c[d]);n.Revenue=u;var p=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"];function v(e){function t(t){e[t]=function(){e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}for(var n=0;n<(null==p?void 0:p.length);n++)t(p[n])}v(n),n.getInstance=function(e){var t,i;return e=(e&&0!==(null===(t=e)||void 0===t?void 0:t.length)?e:"$default_instance").toLowerCase(),null!==(i=n._iq)&&void 0!==i&&i.hasOwnProperty(e)||(n._iq[e]={_q:[]},v(n._iq[e])),n._iq[e]},e.amplitude=n}(window,document);
    
      amplitude.getInstance().init("${AMPLITUDE_KEY}", null, {includeUtm: true,saveEvents: true});`,
    }}
  />
)

export { AMHead }
