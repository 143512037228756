import { IObj } from 'constants/types/common.types'

const NODE_ENV_Type = {
  development: 'development',
  production: 'production',
}
const GLOBAL_ENV_Type = {
  dev: 'dev',
  test: 'test',
  prod: 'prod',
}

const nodeEnv = process.env.NODE_ENV || NODE_ENV_Type.development
const globalEnv = process.env.GLOBAL_ENV || GLOBAL_ENV_Type.dev

const AMPLITUDE_KEY = process.env.AMPLITUDE_KEY
const GA_TRACKING_ID = process.env.GA_TRACKING_ID
const GTM_ID = process.env.GTM_ID
const YM_ID = process.env.YM_ID
const YA_PAY_ENVIRONMENT = process.env.YA_PAY_ENVIRONMENT || 'Sandbox'

const YA_PAY_MERCHANT_ID = process.env.YANDEX_MERCHANT_ID || '598e9c39-6e1c-4638-9557-1e474bcf30ab'

const AB_VERSION = process.env.AB_VERSION

const MAIN_PAGE_URL = '/main'
const PROMO_REDIRECT_URL = '/'

const API_URL = '/rest'
const NEST_API_URL = '/restNest'
const STATIC_PATH_URL = '/static'
const STATIC_PREVIEW_URL = '/imgproxy/insecure/plain/local:'
const AUTH_URL = '/auth'
const EMAIL_URL = '/email'
const ACTIVE_WEBINAR_URL = '/webinarConnect'
const LOGGER_URL = '/webinarLogger'
const STATISTIC_SERVICE_URL = '/statisticService'
const WEBINAR_SERVICE_URL = '/webinarSocketsService'
const WEBSOCKET_URL = '/webinarConnect'
const SET_COOKIE_URL = '/setCookie'
const GAME_URL = `/gamification`

const STATIC_URL = '/img'
const PROJECTS_IMG_URL = '/img/projects'
const PROD_STATIC_PATH = 'https://medpoint.pro/static'

const SeminarData = {
  [GLOBAL_ENV_Type.test]: {
    domain: 'https://kdebug.medpoint.pro',
    materialId: 937,
    key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTIyNzQ5Njd9.NG-BXL2CuCLwdV8u-dwbgwLI5SlPqcUbX-CdD6QBtu8',
  },
  [GLOBAL_ENV_Type.dev]: {
    domain: 'http://127.0.0.1:3005',
    materialId: 937,
    key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTIyNzQ5Njd9.NG-BXL2CuCLwdV8u-dwbgwLI5SlPqcUbX-CdD6QBtu8',
  },
  [GLOBAL_ENV_Type.prod]: {
    domain: 'https://medpoint.pro',
    materialId: 925,
    key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTE5MjcyMzB9.7vgyD3uJUdxlunNa4FhmT6LobwCi0B58Urh2r-ZoUh8',
  },
}
const currSeminarData = SeminarData[globalEnv]

const Domain = {
  [GLOBAL_ENV_Type.test]: 'https://kdebug.medpoint.pro',
  [GLOBAL_ENV_Type.dev]: 'https://kdebug.medpoint.pro',
  [GLOBAL_ENV_Type.prod]: 'https://medpoint.pro',
}

const DomainSite = {
  [GLOBAL_ENV_Type.test]: 'https://kdebug.medpoint.pro',
  [GLOBAL_ENV_Type.dev]: 'http://127.0.0.1:3005',
  [GLOBAL_ENV_Type.prod]: 'https://medpoint.pro',
}

const DomainPharmacy = {
  [GLOBAL_ENV_Type.test]: 'https://kpartners.medpoint.pro/webinar',
  [GLOBAL_ENV_Type.dev]: 'https://kpartners.medpoint.pro/webinar',
  [GLOBAL_ENV_Type.prod]: 'https://partners.medpoint.pro/webinar',
}

const currImageDomain = Domain[globalEnv]
const URL_SITE = DomainSite[globalEnv]
const PODCAST_URL = `${Domain[globalEnv]}/static/podcast`
const URL_PHARMACY = DomainPharmacy[globalEnv]

const generateBaseStaticWidthURL = (width: number) => {
  if (nodeEnv == NODE_ENV_Type.development || !width) return STATIC_URL
  return `/imgproxy/insecure/width:${width}/plain/local:`
}

const LECTUR_SHORT_TRESHOLD = ['mobile', 'tablet']

const Config = {
  HEADERS: {
    'Content-Type': 'application/json; charset=utf-8',
  },
}

enum MaterialTypes {
  WEBINAR = 'webinar',
  FUTURE_WEBINAR = 'future_webinar',
  ARTICLE = 'article',
  PODCAST = 'podcast',
  NMO = 'nmo',
}

enum TypeCodes {
  ARTICLE = 1,
  VIDEO = 2,
  CLINIC = 3,
  CLINIC_CASE = 4,
}

enum StatusVocabulary {
  PAST = 1,
  ACTIVE = 2,
  FUTURE = 3,
  HOUR_BEFORE = 4,
}

const UTMCampaignArticleType: IObj<string, number> = {
  [TypeCodes.ARTICLE]: 'article',
  [TypeCodes.CLINIC]: 'article',
  [TypeCodes.VIDEO]: 'video',
}
const UTMCampaignWebinarType = 'webinar'

enum Pathname {
  LECTURE = 'lecture',
  WEBINAR = 'webinar',
  ARTICLE = 'article',
  CLINIC = 'clinic',
  INTERACTIVE = 'interactive',
  PODCAST = 'podcast',
  CLINICAL = 'clinical-case',
  LIBRARY = 'library',
  PROFILE = 'profile',
  NMO = 'nmo',
  NMO_ACTIVATION = 'nmo/activation',
  NMO_REPORT = 'nmo/report',
  NMO_REQUEST = 'nmo/request',
  NMO_COURSE = 'program',
  NMO_COURSE_CARDIO = 'program/cardio-gold-standard',
  NMO_COURSE_GERIATRICS = 'program/modern-geriatrics',
  MAIN = 'main',
  QUESTIONNAIRE = 'questionnaire',
  CLINICAL_CASE = 'clinical-case',
  CALCULATORS = 'calculators',
  GAME = 'game',
  GAME_PROMO = 'game/promo',
  GAME_HISTORY = 'game/history',
  CROWD_FUNDING = 'pomoshmedikam',
  DOZATOR = 'dozator',
  SANOFI = 'sanofi',
  MAYOLY = 'mayoly',
  VIDEOPROJECTS = 'webinar/videoprojects',
  TELEGRAM = 'https://t.me/medpointpro',
  TELEGRAM_SGH_BOT = 'https://t.me/MedpointSHG_bot',
  GERIATRICS_SCHOOL = 'geriatrics-school',
}

const ABType = {
  A: 'A',
  B: 'B',
}

enum MainColors {
  ORANGE = 'orange',
  BLUE = 'blue',
  PINK = 'pink',
  GREEN = 'green',
  VIOLET = 'violet',
}

export {
  EMAIL_URL,
  API_URL,
  NEST_API_URL,
  STATIC_URL,
  STATIC_PREVIEW_URL,
  STATIC_PATH_URL,
  PROD_STATIC_PATH,
  PROJECTS_IMG_URL,
  WEBSOCKET_URL,
  AUTH_URL,
  ACTIVE_WEBINAR_URL,
  LOGGER_URL,
  STATISTIC_SERVICE_URL,
  WEBINAR_SERVICE_URL,
  PROMO_REDIRECT_URL,
  SET_COOKIE_URL,
  MAIN_PAGE_URL,
  GAME_URL,
  LECTUR_SHORT_TRESHOLD,
  MaterialTypes,
  TypeCodes,
  StatusVocabulary,
  Config,
  Pathname,
  GA_TRACKING_ID,
  GTM_ID,
  YM_ID,
  YA_PAY_ENVIRONMENT,
  AMPLITUDE_KEY,
  YA_PAY_MERCHANT_ID,
  generateBaseStaticWidthURL,
  UTMCampaignArticleType,
  UTMCampaignWebinarType,
  ABType,
  AB_VERSION,
  MainColors,
  currSeminarData,
  currImageDomain,
  PODCAST_URL,
  URL_SITE,
  URL_PHARMACY,
}
