enum LocalStorageKeys {
  COOKIES_AGREE = 'COOKIES_AGREE',
  ATTENTION_AGREE = 'ATTENTION_AGREE',
  TECH_WORKS_ACCEPT = 'TECH_WORKS_ACCEPT',
  QUESTIONNAIRE_MAIL_APP = 'QUESTIONNAIRE_MAIL_APP',
  PUSH_ACCEPTED = 'PUSH_ACCEPTED',
  PUSH_WEEKLY_CHECK = 'PUSH_WEEKLY_CHECK',
  WEBINAR_WATCH_TIME = 'WEBINAR_WATCH_TIME',
  BROKEN_MAIL = 'BROKEN_MAIL',
  DOZATOR_ONBOARDING = 'DOZATOR_ONBOARDING',
  ATTENTION_AGREE_OLD = 'attentionAgree', //comparability with old style
  COOKIES_AGREE_OLD = 'cookiesAgree', //comparability with old style
  ADVERTISING_BANNERS = 'ADVERTISING_BANNERS',
}

enum SessionStorageKeys {
  FIRST_TIME = 'FIRST_TIME',
  DONT_ASK_LOCATION = 'DONT_ASK_LOCATION',
  SUCCESS_REGISTRAION = 'SUCCESS_REGISTRAION',
  INFORM_LINE_HIDDEN = 'INFORM_LINE_HIDDEN',
  ONBOARDING_STATUS = 'ONBOARDING_STATUS',
  ONBOARDING_START_ACTIVE_WEBINAR_SCENARIO = 'ONBOARDING_START_ACTIVE_WEBINAR_SCENARIO',
  DIGEST_SUBSCRIBED = 'DIGEST_SUBSCRIBED',
  GAMIFICATION_JOINED = 'GAMIFICATION_JOINED',
  REQUEST_WEBINAR_RECORD = 'REQUEST_WEBINAR_RECORD',
  ANALYTIC_ORIGIN = 'ANALYTIC_ORIGIN',
}

enum SessionConsultationKeys {
  CONSULTATION_AMO = 'CONSULTATION_AMO',
  CONSULTATION_AMO_AD = 'CONSULTATION_AMO_AD',
  CONSULTATION_AMO_TRIAL = 'CONSULTATION_AMO_TRIAL',
  NMO_REPORT = 'NMO_REPORT',
  NMO_ACCREDITATION = 'NMO_ACCREDITATION',
  NMO_INSTRUCTION = 'NMO_INSTRUCTION',
  NMO_ACTIVATION = 'NMO_ACTIVATION',
  COURSE_PROGRAM = 'COURSE_PROGRAM',
  COURSE_PROGRAM_POPUP = ' COURSE_PROGRAM_POPUP',
  MAIN_PROGRAM = 'MAIN_PROGRAM',
  MAIN_PROGRAM_POPUP = 'MAIN_PROGRAM_POPUP',
  LIBRARY_PROGRAM = 'LIBRARY_PROGRAM',
  LIBRARY_POPUP = 'LIBRARY_POPUP',
}

enum SessionPopupsKeys {
  GERIATRICS_SCHOOL_GER_POPUP = 'GERIATRICS_SCHOOL_GER_POPUP',
}

export { LocalStorageKeys, SessionStorageKeys, SessionConsultationKeys, SessionPopupsKeys }
