import { combineReducers } from 'redux'

import { initialState } from './auth/reducers'
import Auth from './auth'
import Common from './common'
import Lists from './lists'
import NMO from './analytics-nmo'
import NAVBAR from './navbar'

export const inititalStoreState = {
  auth: initialState,
}

export const reducer = combineReducers({
  auth: Auth.reducer,
  common: Common.reducer,
  lists: Lists.reducer,
  nmo: NMO.reducer,
  navbar: NAVBAR.reducer,
})
